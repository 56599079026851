@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");


/* .bg-video {
  background-image: url(../img/AdobeStock_530002571_Video_4K_Preview.mp4);
} */

.border-bi-col {
    border-color: black transparent;
}

.mySwiper2 .swiper-slide {
    height: unset;
}

@media (min-width: 1350px) {
    .container {
        max-width: 1200px !important;
    }
}
@media (min-width: 1350px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1350px) {
    .container {
        max-width: 1200px;
    }
}
html {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}
html::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

#myCheckbox:checked + label {
    background-color: transparent;
}

.checked #checkmark {
    display: block;
}
#formations .swiper { width: 100%; height: 100%; }

.slideContent ul, .content-list ul{
    list-style: disc;
    margin: 1em;
    padding-left: 2.5em;
}
